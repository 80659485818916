import { envs, urls } from '@sentry/shared';
import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';
const { getApiUrl } = urls.create(envs.getAppEnv);

import { getApplicationState } from '$store/application-store';

async function getStargateApi() {
  const {
    queryState: { client_id, relay },
  } = await getApplicationState();

  return wretch('https://auth-stg1.services.adobe.com')
    .addon(QueryStringAddon)
    .headers({
      'x-ims-clientid': client_id,
      'X-Debug-Id': relay,
    });
}

async function getIMSApi(qsOverride?: { client_id: string; relay: string }) {
  const apiUrl = getApiUrl();

  const { queryState } = qsOverride
    ? { queryState: qsOverride }
    : await getApplicationState();

  const { client_id, relay } = queryState;

  return wretch(apiUrl).addon(QueryStringAddon).headers({
    'x-ims-clientid': client_id,
    'X-Debug-Id': relay,
  });
}

export { getIMSApi, getStargateApi };
