import { i18nLoading } from '$locales/helpers';
import { AnalyticsState } from '$store/analytics-store';
import { ApplicationState } from '$store/application-store';
import {
  ConfigurationsState,
  getConfigurationsStore,
} from '$store/configurations-store';
import { SentryError, convertError } from '@sentry/shared';
import { createPageLoadEvent } from './analytics/events';
import { AsyncWrapper } from './async-wrapper';
import { openModalDispatcher } from './events/dispatchers';
import { AppEvents, MobileEvents } from './events/events';
import { features } from './features';
import { CriticalOutcome } from './outcome';

export interface CustomElement extends HTMLElement {
  controller?: any;
  state?: Record<string, any>;
}

// Wait 150ms before showing the spinner, to avoid flickering
// If it takes more than 150ms to load the data, show a spinner for at least 500ms
const DELAY = 150;
const MINIMUM_DISPLAY_TIME = 500;
let spinnerTimeout: NodeJS.Timeout | null = null;
let spinnerStartTime: number = 0;

// start showing the spinner if we don't receive data before ${DELAY} miliseconds
const startLoading = (element: HTMLElement, loading = true) => {
  spinnerTimeout = setTimeout(() => {
    element.classList.toggle('sentry-loading', loading);
    spinnerStartTime = Date.now();
  }, DELAY);
};

const stopLoading = (element: HTMLElement, loading = true) => {
  // if we receive data before ${DELAY} miliseconds, don't show the spinner at all
  if (!spinnerStartTime) {
    if (spinnerTimeout !== null) {
      clearTimeout(spinnerTimeout);
      spinnerTimeout = null;
    }

    return;
  }

  const elapsedTime = Date.now() - spinnerStartTime;
  const remainingTime = Math.max(MINIMUM_DISPLAY_TIME - elapsedTime, 0);

  setTimeout(() => {
    element.classList.toggle('sentry-loading', loading);
  }, remainingTime);

  spinnerStartTime = 0;
};

const setLoading = (element: HTMLElement, loading = true) => {
  if (loading) {
    startLoading(element, loading);
  } else {
    stopLoading(element, loading);
  }
};

export type CreatePageParams<TState> = {
  initialState: TState;
  dimensions: { width: string; height: string };
  forceModal: boolean;
};

export function pageCreator(
  root: HTMLElement | null,
  states: {
    getAnalytics: () => AnalyticsState;
    getConfigurationsState: () => ConfigurationsState;
    getApplicationState: () => Promise<ApplicationState>;
  }
) {
  return async function createPage<TOutcome, TState>(
    loader: () => any,
    { initialState, dimensions, forceModal }: CreatePageParams<TState>
  ): Promise<TOutcome | CriticalOutcome> {
    const { getAnalytics, getConfigurationsState, getApplicationState } =
      states;
    try {
      if (features.hasModalMode()) {
        setLoading(document.body, true);
      }

      if (!root) {
        throw SentryError.unrecoverable('No root element found.');
      }

      if (features.hasModalMode()) {
        const { config, events, eventListener, port } =
          await getApplicationState();
        if (
          dimensions &&
          (config.modal || forceModal) &&
          port instanceof MessagePort
        ) {
          // Remove the current page to prevent a flash of content when the modal opens
          document.querySelector('app-wrapper')?.remove();

          await openModalDispatcher(
            port,
            events as AppEvents,
            eventListener(),
            dimensions
          );
        }
      }

      const { Controller, getPageStore, PAGE_TAGNAME, initializePageState } =
        await loader();

      const { push } = getAnalytics();
      push(
        createPageLoadEvent({
          pageName: `Account:IMS-Light:${PAGE_TAGNAME}:OnLoad`,
        })
      );

      const { isLocaleLoading, localeLoadingPromise } = i18nLoading();
      const store = getPageStore();

      const controller = new Controller();
      controller.store = store;

      if (isLocaleLoading) {
        await localeLoadingPromise;
      }

      const pageElement = mountPage(root, PAGE_TAGNAME);
      pageElement.controller = controller;

      // Initialize page state using initializer if provided, otherwise set initial state.
      initializePageState
        ? initializePageState(store, initialState)
        : store.setState({ ...initialState, loading: false });

      store.subscribe((state: any) => (pageElement.state = state));
      store.setState({ loading: false });

      const getAsync = () => store.getState()?.async;
      const async = getAsync();

      if (async) {
        Object.entries(async).forEach(([key, value]: [string, unknown]) => {
          if (value instanceof AsyncWrapper) {
            value.onFulfilled(() => {
              store.setState({
                async: { ...getAsync(), [key]: AsyncWrapper.from(value) },
              });
            });
          }
        });
      }

      // Send web load event
      const { hasSentLoadEvent } = getConfigurationsState();
      const { events, queryState } = await getApplicationState();

      if (!hasSentLoadEvent) {
        if (queryState.wrapper) {
          (events as AppEvents).sendLoad({ ready: true }); // SUSI Light
        } else {
          (events as MobileEvents).sendHello({ name: 'hello' }); // UME
        }

        getConfigurationsStore().setState({ hasSentLoadEvent: true });
      }

      if (features.hasModalMode()) {
        setLoading(document.body, false);
      }

      return controller.outcomePromise.promise;
    } catch (e) {
      return Promise.reject(new CriticalOutcome(convertError(e)));
    }
  };
}

export function mountPage(
  root: HTMLElement,
  pageIdentifier: string
): CustomElement {
  const appWrapper = document.createElement('app-wrapper');
  root.innerHTML = ''; // Clear the root element
  root.appendChild(appWrapper);

  const element = document.createElement(pageIdentifier);
  appWrapper.appendChild(element);

  return element;
}
