import { openPopupDispatcher } from '$scripts/events/dispatchers';
import { QueryState, queryState as qs, qsModifier } from '$scripts/query-state';
import { SocialProvider } from '$shared/types/social-provider';
import { Event, EventScheduler } from '@sentry/shared';
import { AppEvents } from '../events/events';
import { URLSearchParamsBuilder } from './builder';
import {
  createAccountAdobePopupUrl,
  federatedPopupUrl,
  passkeyDeeplinkPopupUrl,
  signInAdobePopupUrl,
  socialWebDeeplinkPopupUrl,
} from './list';

type QueryStateModifierFn = (queryState: QueryState) => QueryState;

function popupHandler<TEvent extends Event>(
  returnedEvent: TEvent,
  urlBuilder: URLSearchParamsBuilder,
  queryStateModifiers: QueryStateModifierFn[] = []
) {
  return async (
    events: AppEvents,
    eventScheduler: EventScheduler,
    queryState: QueryState
  ) => {
    const query = qsModifier.omit(queryState, ['redirect_uri']);

    const url = urlBuilder(
      qs.toObject(
        queryStateModifiers.reduce((acc, modifier) => modifier(acc), query)
      )
    );

    try {
      // We should not error just because we can't use Session Storage. It is just used for tracking purposes.
      sessionStorage.setItem('client_id', queryState.client_id);
      sessionStorage.setItem('relay', queryState.relay);
    } catch (e) {}

    return openPopupDispatcher(returnedEvent, url, events, eventScheduler);
  };
}

const addSUSILightOriginCheck = (queryState: QueryState) => ({
  ...queryState,
  ...(queryState.wrapper ? { sl: queryState.asserted_origin } : {}),
});

export const AccessTokenFlow = Event.create<{ access_token: string }>({
  side: 'popup',
  name: 'AccessTokenFlow',
  data: { access_token: '' },
});

export const AuthCodeFlow = Event.create<{ code: string }>({
  side: 'popup',
  name: 'AuthCodeFlow',
  data: { code: '' },
});

export const FederatedJWTFlow = Event.create<{
  token: string;
}>({
  side: 'popup',
  name: 'FederatedJWTFlow',
  data: { token: '' },
});

type PopupFlows =
  | typeof AccessTokenFlow
  | typeof AuthCodeFlow
  | typeof FederatedJWTFlow;
export const createPopupHandler = <T extends PopupFlows>(
  flow: T,
  urlBuilder: URLSearchParamsBuilder
) => popupHandler<T>(flow, urlBuilder, [addSUSILightOriginCheck]);

export const signInPasskeyPopup = {
  withAccessToken: createPopupHandler(AccessTokenFlow, passkeyDeeplinkPopupUrl),
  withAuthCode: createPopupHandler(AuthCodeFlow, passkeyDeeplinkPopupUrl),
};

export const signInAdobePopup = {
  withAccessToken: createPopupHandler(AccessTokenFlow, signInAdobePopupUrl),
  withAuthCode: createPopupHandler(AuthCodeFlow, signInAdobePopupUrl),
};

export const createAccountAdobePopup = {
  withAccessToken: createPopupHandler(
    AccessTokenFlow,
    createAccountAdobePopupUrl
  ),
  withAuthCode: createPopupHandler(AuthCodeFlow, createAccountAdobePopupUrl),
};

export const socialWebDeeplinkPopup = (provider: SocialProvider) => ({
  withAccessToken: createPopupHandler(
    AccessTokenFlow,
    socialWebDeeplinkPopupUrl({ provider_id: provider })
  ),
  withAuthCode: createPopupHandler(
    AuthCodeFlow,
    socialWebDeeplinkPopupUrl({ provider_id: provider })
  ),
});

export const socialFederatedPopup = (provider: SocialProvider) =>
  createPopupHandler(FederatedJWTFlow, federatedPopupUrl(provider));
