import { Outcome } from '$scripts/outcome';
import { AuthFailReason } from '$shared/types/auth-failed-reasons';

export class RedirectOutcome extends Outcome<{
  url: string;
}> {}
export class TokenOutcome extends Outcome<{ token: string }> {}
export class AuthCodeOutcome extends Outcome<{ code: string }> {}
export class AuthFailedOutcome extends Outcome<{
  reason: AuthFailReason;
  fallbackUrl?: string;
}> {}

export class CompleteAccountRequiredOutcome extends Outcome<{
  fields: string[];
  token: string;
}> {}
